<template>
  <div id="issue">
    <div class="header">
      <van-row gutter="10">
        <van-col span="10" class="header-title" @click="onClickLeft">
          <svg-icon
            :data_iconName="'back-active'"
            :className="'back_icon'"
          />日报/周报
        </van-col>
        <van-col span="14" class="header-title" style="text-align: right">
          <div class="submit-btn" @click="post_reports1" v-if="active == 1">
            立即提交
          </div>
          <div class="submit-btn" @click="post_reports" v-if="active == 0">
            立即提交
          </div>
        </van-col>
      </van-row>
    </div>
    <div class="issue-wrapper">
      <van-tabs color="#3A5BE9" type="card" v-model="active">
        <van-tab title="日报">
          <div class="issue-container">
            <van-row class="reply-container">
              <van-col span="24">
                <textarea
                  class="reply-text-area"
                  placeholder="请输入内容~"
                  v-model="report.report_content"
                  name=""
                  id=""
                  cols="20"
                  rows="5"
                ></textarea>
              </van-col>
            </van-row>
            <div class="flex">
              <van-uploader
                max-count="1"
                v-model="fileList"
                
                accept="image/*"
                :after-read="fnUploadRequest"
              />
            </div>
            <div class="btn" @click="post_reports">立即提交</div>
          </div>
        </van-tab>
        <van-tab title="周报">
          <div class="issue-container">
            <van-row class="reply-container">
              <van-col span="24">
                <textarea
                  class="reply-text-area"
                  placeholder="请输入内容~"
                  v-model="report1.report_content"
                  name=""
                  id=""
                  cols="20"
                  rows="5"
                ></textarea>
              </van-col>
            </van-row>
            <div class="flex">
              <van-uploader
                max-count="1"
                v-model="fileList1"
                
                accept="image/*"
                :after-read="fnUploadRequest1"
              />
            </div>
            <div class="btn" @click="post_reports1">立即提交</div>
          </div>
        </van-tab>
      </van-tabs>
     
    </div>
  </div>
</template>

<script>
import oss from "@/utils/oss";
import { Toast } from "vant";

export default {
  name: "issue",
  data() {
    const _self = this;
    return {
      active: 0,

      fileList: [],
      fileList1: [],
      report:{
          report_type:'0',
          report_content:"",
          attachment_url:""
      },
      report1:{
          report_type:'1',
          report_content:"",
          attachment_url:""
      }
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    async post_reports() {
     const _self = this;
      if(this.report.report_content==""){
          Toast('请填写日报内容')
          return false
      }
      if(_self.fileList.length>0){
      _self.report.attachment_url = _self.fileList[0].url;
      }
      const res = await this.$apis.post_reports(this.report)
      if(res.status==200){
          Toast('提交成功')
          this.$router.go(-1)
      }
    },
    async post_reports1() {
      const _self = this;
      if(this.report1.report_content==""){
          Toast('请填写周报内容')
          return false
      }
      if(_self.fileList1.length>0){
        _self.report1.attachment_url = _self.fileList1[0].url;
      }
     
      const res = await this.$apis.post_reports(this.report1)
      if(res.status==200){
          Toast('提交成功')
          this.$router.go(-1)
      }
    },
    fnUploadRequest(option) { 
      const _self = this;
      Toast.loading({
        message: "上传中...",
        forbidClick: true,
      });
      oss.ossUploadFile(option).then((res) => {
        Toast.clear();
        _self.fileList = [{ url: res.fileUrl[0].split("?")[0] }];
      });
    },
    fnUploadRequest1(option) {
      const _self = this;
      Toast.loading({
        message: "上传中...",
        forbidClick: true,
      });
      oss.ossUploadFile(option).then((res) => {
        Toast.clear();
        _self.fileList1 = [{ url: res.fileUrl[0].split("?")[0] }];
      });
    },
  
  },
  components: {
    
  },
};
</script>

<style scoped>
#issue {
  height: 100vh;
  background: #f3f3f3;
  padding-top: 10vh;
}
.header {
  height: 10vh;
  line-height: 10vh;
  padding: 0 0.3rem;
}
.header-title {
  color: #333;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
}
.issue-wrapper {
  height: 88vh;
  background: #ffffff;
  box-shadow: 0 0 6px 0 rgba(182, 179, 179, 0.54);
  border-radius: 16px;
  border-radius: 16px;
  padding-top: 0.3rem;
  width: 95%;
  margin: 0 auto;
}
.issue-container {
  background: #fff;
  margin-top: 0.2rem;
  padding: 0.2rem;
}
.quill-editor {
  height: 40vh !important;
}
.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 5px;
  position: relative;
}
.flex-box {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  min-width: 0;
  font-size: 14px;
  color: #333;
}
.flex label {
  color: #000000;
  font-size: 0.3rem;
  width: 80px;
}

.flex-box input {
  background: none;
  border: none;
  padding: 0.1rem 0;
  height: 1rem;
  line-height: 1rem;
  width: 100%;
}
.reply-container {
  margin-top: 20px;
}
.reply-text-area {
  border-radius: 0.2rem;
  background: #f3f3f3;
  width: 100%;
  padding: 0.3rem;
  font-size: 0.3rem;
}
.submit-container {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1.4rem;
  background-color: #fff;
  line-height: 1.4rem;
  text-align: center;
}
.submit-btn {
  text-align: center;
  color: #333;
  font-size: 0.4rem;
  font-weight: bold;
  border-radius: 0.5rem;
  float: right;
}
.btn {
  height: 0.8rem;
  text-align: center;
  color: #436ef3;
  background: #ecf0fe;
  line-height: 0.8rem;
  /* margin-top:.4rem; */
  font-size: 0.3rem;
  border-radius: 5px;
}
</style>
